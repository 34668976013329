.custom-fixed-table {
    max-width: 100%;
    max-height: 700px;
    overflow: scroll;
    position: relative;
}

table {
    position: relative;
    border-collapse: collapse;
}

td,
th {
    padding: 0.25em;
    /*min-width: 170px;*/
}

thead th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
    background: #b9b9b9;
}

thead th:nth-child(1) {
    left: 0;
    z-index: 1;
}

/*tbody .custom-fix {
    position: -webkit-sticky; !* for Safari *!
    position: sticky;
    left: 0;
    background: #FFF;
    border-right: 1px solid #CCC;
}*/


